import React, { Component } from 'react';
import { connect } from 'react-redux'
import { adServissActions, adServissKonfiguracijaActions, adServissKonfiguracijaAktivsActions, adImportDataActions, adImportStacijasNoverojumiActions } from '../../actions/adServisiActions'
import Form from '../forms/Form'
import { Button } from "reactstrap";
import { getKlasifikatorsFieldCfg, getDefaultRendererForMultiField, getKodifRenderer } from "../forms/FormHelpers";
import { dateFormatter } from '../grid/ColumnHelpers';
import { parse } from '@datasert/cronjs-parser';
import Loading from '../Loading'
import { FormikConsumer } from 'formik';

const getFormFieldCfgs = ({kodif, fetchAct, changeActiveStatusMainPage}) => {
    const fields = [
      {
        name: 'nosaukums',
        label: 'Nosaukums',
        type: 'text',
        required: true,
        editable: true
      }, {
        name: 'konfigurācijas',
        label: 'Konfigurācijas',
        sizeForm: 20,
        editable: false,
        multiFieldCfgs: [{
          name: 'edit_icon',
          renderer: (val, row) => {
            return <>
              <Button color="success" outline size="sm" onClick={() => { fetchAct({ id: row.id }) }}><i className="fa fa-pencil"></i></Button> {' '}
            </>
          }
        }, {
          name: 'toggle_aktivs',
          renderer: (val, row) => {
            return <> 
              <Button color="primary" outline size="sm" onClick={() => { changeActiveStatusMainPage({ id: row.id, ir_aktīvs: !row.ir_aktīvs}) }}>{row.ir_aktīvs ? 'Izslēgt' : 'Ieslēgt'}</Button>
            </>
          }
        },{
          name: 'ir_aktīvs',
          label: 'Ir aktīvs',
          type: 'checkbox'
        }, {  
          name: 'konektors',
          label: 'Konektors',
          renderer: getKodifRenderer(kodif.adServisaKonektors),
        }, {
          name: 'tips',
          label: 'Tips',
          renderer: getKodifRenderer(kodif.adServisaTips)
        }, {
          name: 'crontab',
          label: 'Crontab'
        }, {
          name: 'data_source',
          label: 'Datu avots',
          renderer: getKodifRenderer(kodif.adDataSources)
        }, {
          name: 'perioda_sākuma_datuma_izteiksme',
          label: 'Sākuma datuma izteiksme'
        }, {
          name: 'perioda_beigu_datuma_izteiksme',
          label: 'Beigu datuma izteiksme'
        }, {
          name: 'uid_izteiksme',
          label: 'UID izteiksme'
        }, {
          name: 'izveidošanas_datums',
          label: 'Izveidošanas datums',
          renderer: dateFormatter
        }, {
          name: 'labošanas_datums',
          label: 'Labošanas datums',
          renderer: dateFormatter
        }
    ]}
  ]
  return fields
}

const getModalFieldCfg = ({ kodif }) => {
  return [{
    name: 'ir_aktīvs',
    label: 'Ir aktīvs',
    type: 'checkbox',
    toolTip: 'Pazīme vai konfigurācija ir aktīva.'
  }, {
    ...getKlasifikatorsFieldCfg(kodif, 'adServisaKonektors'),
    name: 'konektors',
    required: true,
    label: 'Konektors',
    renderer: getKodifRenderer(kodif.adServisaKonektors),
    toolTip: 'Metode kā tiks izgūti dati.',
  }, {
    ...getKlasifikatorsFieldCfg(kodif, 'adServisaTips'),
    name: 'tips',
    required: true,
    label: 'Tips',
    renderer: getKodifRenderer(kodif.adServisaTips),
    toolTip: 'Veids kā tiek startēts datu ielādes process.'
  }, {
    name: 'datu_kopas_id',
    required: true,
    label: 'Datu kopa',
    toolTip: 'Pēc datu ielādes tie tiek nodoti uz atvērtot datu portālu ar konkrēto identifikātoru.'
  }, {
    name: 'crontab',
    label: 'Cron izteiksme',
    hiddenFunc: ({ currentValues }) => currentValues.tips !== 'automātisks',
    validation: (values) => {
      if (values.tips === 'automātisks') {
        if (!values.crontab || values.crontab.trim() === '') {
          return 'Obligāts lauks';
        }
        try {
          parse(values.crontab, { hasSeconds: true }) } 
        catch(e) { 
            return 'Cron izteiksme nav derīga';
        }
      }
    },
    toolTip: 'Izteiksme izmantojot cron sintaksi, kas nosaka automātiskā datu ielādes procesa regularītāti.'
  }, {
    name: 'perioda_sākuma_datuma_izteiksme',
    required: true,
    label: 'Perioda sākuma datuma izteiksme',
    toolTip: 'Perioda sākuma datuma izteiksme no kuras tiek aprēķināts mērijuma ņemšanas sākuma datums.'
  }, {
    name: 'perioda_beigu_datuma_izteiksme',
    required: true,
    label: 'Perioda beigu datuma izteiksme',
    toolTip: 'Perioda beigu datuma izteiksme no kuras tiek aprēķināts mērijuma ņemšanas beigu datums.'
  }, {
    name: 'nosaukuma_izteiksme',
    required: true,
    label: 'Nosaukuma izteiksme',
    toolTip: 'Datu kopas nosaukums, kas tiek izmantots ģenerējot tekošo datumu un perioda sākuma un beigu datumus.'
  }, {
    name: 'uid_izteiksme',
    required: true,
    label: 'UID izteiksme',
    toolTip: 'Gadijumā, ja datu izgūšana uz periodu tiek veikta atkārtoti tad izteiksme jāformē tā, lai tā sakrsitu tiem ierakstiem, kas jāatjauno, nevis jāveido jauns ieraksts.'
  }, {
    ...getKlasifikatorsFieldCfg(kodif, 'adDataSources'),
    name: 'data_source',
    label: 'Datu avots',
    renderer: getKodifRenderer(kodif.adDataSources),
    hiddenFunc: ({ currentValues }) => currentValues.konektors !== 'sql',
    toolTip: 'Sistēmā definēta datasource, uz kuru tiks veikts vaicājums.'
  }, {
    name: 'vaicājums',
    label: 'Vaicājums',
    type: 'textarea',
    hiddenFunc: ({ currentValues }) => currentValues.konektors !== 'sql',
    toolTip: 'yaml formātā pierakstīts vaicājums.',
    renderer: (fieldValue) => (<span className="display-linebreak">{fieldValue}</span>)
  }, {
    name: 'stacijas',
    label: 'Stacijas',
    type: 'autocomplete',
    autocompleteCfg: {
        multiple: true,
        keyField: 'tah_id',
        initialOptionsViewName: 'ad_stacijas_list',
        extraFilterParams : {},
        isValueObjStructure: true
    },
    renderer: getDefaultRendererForMultiField('tah_label'),
    hiddenFunc: ({ currentValues }) => currentValues.konektors !== 'gaiss' || currentValues.ir_ieraksts,
    overridePropsFunc: (props) => {
      const { currentValues } = props
      const excludeIds = currentValues.stacijas?.map((item) => item.tah_id);
      const extraFilterParams = excludeIds && excludeIds.length > 0 ? { exclude_ids: excludeIds }: {}
      return {
        ...props,
        autocompleteCfg: {
          ...props.autocompleteCfg,
          extraFilterParams,
        },
      }
    },
    toolTip: 'Stacijas uz kurām jāpieprasa dati.'
  }, {
    name: 'stacijas',
    label: 'Stacijas',
    editable: false,
    sizeForm: 20,
    hiddenFunc: ({ currentValues}) => currentValues.konektors !== 'gaiss' || !currentValues.ir_ieraksts,
    toolTip: 'Stacijas uz kurām jāpieprasa dati.',
    multiFieldCfgs: [ 
    {
      name: 'nosaukums',
      label: 'Nosaukums',
    }, {
      name: 'tips',
      label: 'Tips'
    }, {
      name: 'grupa',
      label: 'Grupa'
    }, {
      name: 'augstums_vjl',
      label: 'Augstums virs jūras līmeņa'
    }, {
      name: 'ir_aktīvs',
      label: 'Ir aktīva',
      type: 'checkbox'
    }]
  }, {
    name: 'atmosferas_novērojumi',
    label: 'Atmosfēras novērojumi',
    type: 'autocomplete',
    autocompleteCfg: {
        multiple: true,
        keyField: 'tah_id',
        initialOptionsViewName: 'ad_atmosferas_novērojumi_list',
        extraFilterParams : {},
        isValueObjStructure: true
    },
    renderer: getDefaultRendererForMultiField('tah_label'),
    hiddenFunc: ({ currentValues}) => currentValues.konektors !== 'gaiss' || currentValues.ir_ieraksts,
    toolTip: 'Atmosfēras novērujumi par kuriem jāiegūst dati.',
    overridePropsFunc: (props) => {
      const { currentValues } = props
      const excludeIds = currentValues.atmosferas_novērojumi?.map((item) => item.tah_id);
      const extraFilterParams = excludeIds && excludeIds.length > 0 ? { exclude_ids: excludeIds } : {}
      return {
        ...props,
        autocompleteCfg: {
          ...props.autocompleteCfg,
          extraFilterParams,
        },
      }
    },
  }, {
    name: 'atmosferas_novērojumi',
    label: 'Atmosfēras novērojumi',
    sizeForm: 20,
    hiddenFunc: ({ currentValues}) => currentValues.konektors !== 'gaiss' || !currentValues.ir_ieraksts,
    toolTip: 'Atmosfēras novērujumi par kuriem jāiegūst dati.',
    multiFieldCfgs: [ {
      name: 'nosaukums',
      label: 'Nosaukums'
    }, {
      name: 'kods',
      label: 'Kods'
    }, {
      name: 'mērvienība',
      label: 'Mērvienība'
    }, {
      name: 'ir_aktīvs',
      label: 'Ir aktīvs',
      type: 'checkbox'
    }]
  }, {
    name: 'nokrisnu_novērojumi',
    label: 'Nokrišņu novērujumi ',
    type: 'autocomplete',
    autocompleteCfg: {
        multiple: true,
        keyField: 'tah_id',
        initialOptionsViewName: 'ad_nokrisnu_novērojumi_list',
        extraFilterParams : {},
        isValueObjStructure: true
    },
    renderer: getDefaultRendererForMultiField('tah_label'),
    hiddenFunc: ({ currentValues}) => currentValues.konektors !== 'gaiss' || currentValues.ir_ieraksts,
    toolTip: 'Nokrišņu novērujumi par kuriem jāiegūst dati.',
    overridePropsFunc: (props) => {
      const { currentValues } = props
      const excludeIds = currentValues.nokrisnu_novērojumi?.map((item) => item.tah_id)
      const extraFilterParams = excludeIds && excludeIds.length > 0 ? { exclude_ids: excludeIds } : {}
      return {
        ...props,
        autocompleteCfg: {
          ...props.autocompleteCfg,
          extraFilterParams,
        },
      }
    },
  }, {
    name: 'nokrisnu_novērojumi',
    label: 'Nokrišņu novērojumi',
    sizeForm: 20,
    hiddenFunc: ({ currentValues}) => currentValues.konektors !== 'gaiss' || !currentValues.ir_ieraksts,
    toolTip: 'Nokrišņu novērujumi par kuriem jāiegūst dati.',
    multiFieldCfgs: [ {
      name: 'nosaukums',
      label: 'Nosaukums'
    }, {
      name: 'kods',
      label: 'Kods'
    }, {
      name: 'mērvienība',
      label: 'Mērvienība'
    }, {
      name: 'ir_aktīvs',
      label: 'Ir aktīvs',
      type: 'checkbox'
    }]
  }, {
    ...getKlasifikatorsFieldCfg(kodif, 'adEksportaDatuFormats'),
    name: 'rezultāta_formāts',
    required: true,
    label: 'Rezultāta formāts',
    renderer: getKodifRenderer(kodif.adEksportaDatuFormats),
    toolTip: 'Formāts, kādā dati tiks eksportēti vai lejupielādēti.',
    hiddenFunc: ({ currentValues}) => currentValues.konektors !== 'sql'
  }, {
    name: 'apraksts',
    label: 'Apraksts',
    type: 'textarea',
    required: true,
    editable: true,
    toolTip: 'Datu kopas apraksts, kas tiek padots uz atvērto datu protālu.',
    renderer: (fieldValue) => (<span className="display-linebreak">{fieldValue}</span>)
  }, {
    name: 'izveidotājs',
    label: 'Konfigurācijas izveidotājs',
    editable: false,
    hiddenFunc: ({ currentValues}) => currentValues.izveidotājs === null,
    renderer:  (value) => {
      return value?.personas_dati
    }
  }, {
    name: 'izveidošanas_datums',
    label: 'Izveidošanas datums',
    editable: false,
    hiddenFunc: ({ currentValues}) => currentValues.izveidošanas_datums === null,
    renderer: dateFormatter
  }, {
    name: 'labotājs',
    label: 'Konfigurācijas labotājs',
    editable: false,
    hiddenFunc: ({ currentValues}) => currentValues.labotājs === null,
    renderer:  (value) => {
      return value?.personas_dati
    }
  }, {
    name: 'labošanas_datums',
    label: 'Labošanas datums',
    editable: false,
    hiddenFunc: ({ currentValues}) => currentValues.labošanas_datums === null,
    renderer: dateFormatter
  }
  ]
}

class KonfiguracijaButtonsComp extends Component {
  createConfig = () => {
    const { selectedItem: ad_serviss } = this.props.ad_serviss
    this.props.createConfig({
      ad_serviss_id: ad_serviss.id,
      editable: this.props.konfiguracija.editable
    })
  }

  render() {
    return <>
      <Button className="float-right" color="secondary" onClick={this.createConfig}>Pievienot konfigurāciju</Button>
    </>
  }
}


class KonfiguracijaActionButtons extends Component {
  render() {
    const { konfiguracija, changeActiveStatus, stopEdit, importIerakstsData, importStacijasNoverojumi } = this.props;
    const { selectedItem } = konfiguracija;

    return (
      <FormikConsumer>
        {formik => {
          const { values } = formik;

          return (
            <>
              {selectedItem.ir_ieraksts && 
                <div className="alert alert-warning">
                  Konfigurāciju nevar labot vai dzēst, jo uz tās ir izveidots ieraksts.
                </div>
              }
              {konfiguracija.returnType === 'ad_import_data_save_failure' && 
                <div className="alert alert-danger">
                  Kļūda ģenerējot ierakstu.
                </div>
              }
              {konfiguracija.returnType === 'ad_import_data_save_success' && 
                <div className="alert alert-success">
                  Ieraksts veiksmīgi ģenerēts.
                </div>
              }

              {(!selectedItem.ir_ieraksts && values.konektors === 'gaiss') &&
                <Button className="float-right" color="secondary" onClick={() => importStacijasNoverojumi({id: selectedItem.id})}>
                  Ielādēt stacijas un novērojumus
                </Button>
              }
              {selectedItem.id &&
                <Button className="float-left" color="primary" onClick={() => importIerakstsData({id : selectedItem.id})}>
                  Ģenerēt ierakstu
                </Button>
              }
              {selectedItem.ir_ieraksts &&
                <>
                  <Button className="float-left" color="primary"
                    onClick={() =>
                      changeActiveStatus({
                        id: selectedItem.id,
                        ir_aktīvs: !selectedItem.ir_aktīvs,
                      })
                    }>
                    {selectedItem.ir_aktīvs ? 'Izslēgt konfigurāciju' : 'Ieslēgt konfigurāciju'}
                  </Button>
                  <KonfiguracijaButtons></KonfiguracijaButtons>
                </>
              }
              {selectedItem.ir_ieraksts &&
                <Button className="float-left" color="secondary" onClick={stopEdit}>
                  Atgriezties
                </Button>
              }
            </>
          )
        }}
      </FormikConsumer>
    );
  }
}

class KonfiguracijaForm extends Component {

  render() {
    const { konfiguracija, kodif, adLabot } = this.props

    if (!this.konfiguracijaFieldCfgs) {
      this.konfiguracijaFieldCfgs = getModalFieldCfg({ kodif })
    }
    return (<>
      {!konfiguracija.loading ? 
      <Form
        editable={!konfiguracija.selectedItem.ir_ieraksts ? true : false}
        dataObj={konfiguracija.selectedItem}
        onSubmit={this.props.saveAct}
        onReset={this.props.stopEdit}
        onDelete={this.props.deleteAct}
        formFieldCfgs={this.konfiguracijaFieldCfgs}
        metadataFormCfg="ad_serviss_konfiguracija_edit"
        onStartEdit={null}
        sizeLabel={3}
        tiesibasLabot={adLabot}
        extraElements={
            <KonfiguracijaActionButtons
              konfiguracija={konfiguracija}
              changeActiveStatus={this.props.changeActiveStatus}
              stopEdit={this.props.stopEdit}
              importIerakstsData={this.props.importIerakstsData}
              importStacijasNoverojumi={this.props.importStacijasNoverojumi}
            />}
      /> 
      : <Loading/>}
    </>)
  }
}

class AdServiss extends Component {

    render() {
      const {ad_serviss, konfiguracija, kodif, fetchAct, saveAct, stopEditAct, startEditAct, changeActiveStatusMainPage, user} = this.props
      const adLabot = user.lomas.includes('AD_LABOT')
      if(!this.formFieldCfgs){
        this.formFieldCfgs = getFormFieldCfgs({
            kodif, fetchAct, changeActiveStatusMainPage
        })
    }

    const {editable, submiting, selectedItem} = ad_serviss
    

    const actionButtons = <KonfiguracijaButtons></KonfiguracijaButtons>
    
    return (
        <div className="form-wrapper-pane">
          {!konfiguracija.editable ?
            (<Form
                editable={editable}
                submiting={submiting}
                dataObj={selectedItem}
                formFieldCfgs={this.formFieldCfgs}
                onSubmit={saveAct}
                onReset={stopEditAct}
                onStartEdit={startEditAct}
                tiesibasLabot={adLabot}
                extraElements={actionButtons}
            />) :
            (<Konfiguracija adLabot={adLabot}></Konfiguracija>)
          }
        </div>
    )
  }
}

const KonfiguracijaButtons = connect(({ ad_serviss, konfiguracija, loading }) => ({ ad_serviss, konfiguracija, loading }), {
  createConfig: adServissKonfiguracijaActions.createAct
})(KonfiguracijaButtonsComp)

const Konfiguracija = connect(({
  ad_serviss,
  konfiguracija,
  kodif,
  loading
}) => ({
  ad_serviss,
  konfiguracija,
  kodif,
  loading
}), {
  stopEdit: adServissKonfiguracijaActions.stopEditAct,
  saveAct: adServissKonfiguracijaActions.saveAct,
  deleteAct: adServissKonfiguracijaActions.deleteAct,
  changeActiveStatus: adServissKonfiguracijaAktivsActions.saveAct,
  importIerakstsData: adImportDataActions.saveAct,
  importStacijasNoverojumi: adImportStacijasNoverojumiActions.saveAct
})(KonfiguracijaForm)

const mapStateToProps = ({ad_serviss, konfiguracija, kodif, user}) => ({ad_serviss, konfiguracija, kodif, user})

export default connect(mapStateToProps, {
    ...adServissActions, 
    fetchAct: adServissKonfiguracijaActions.fetchAct,
    deleteAct: adServissKonfiguracijaActions.deleteAct,
    changeActiveStatusMainPage: adServissKonfiguracijaAktivsActions.saveAct
  })(AdServiss)
